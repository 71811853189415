<template>
  <v-btn :disabled="disabled" color="primary" @click="nextStep">
    Continue
  </v-btn>
</template>

<script>
export default {
  name: 'KurccSiteStepperStepContinueButton',
  props: {
    currentStep: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    nextStep () {
      this.$emit('update:current-step', parseInt(this.currentStep) + 1)
    }
  }
}
</script>
